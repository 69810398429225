<template>
  <modal
    :title="'Analytics - ' + modalTitle[nodeName]"
    :handler="true"
    :isLoading="isLoading"
    :cssClass="'modal-md-5-480 flow-analytics-modal' + modalClasses[nodeName]"
    @modalHandler="modalHandler"
    type="pulsing"
  >
    <template v-slot:content>
      <div v-if="!isLoading && !hasError">
        <div
          v-if="
            [
              'metricsTrigger',
              'tagAssignedTrigger',
              'tagRemovedTrigger',
              'emailActionTrigger',
              'enterSegmentTrigger',
              'listEntryTrigger',
              'dateAttributeTrigger',
              'priceDropTrigger',
              'lowStockAlertTrigger',
            ].includes(nodeName)
          "
        >
          <div class="border-top-normal-1 border-bottom-normal-1 py-28 px-2">
            <div class="text-center">
              <h3 class="font-24 font-w-500 base-color mb-2">
                <span class="me-2">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_14488_54179)">
                      <path
                        d="M17.5 21V19C17.5 17.9391 17.0786 16.9217 16.3284 16.1716C15.5783 15.4214 14.5609 15 13.5 15H5.5C4.43913 15 3.42172 15.4214 2.67157 16.1716C1.92143 16.9217 1.5 17.9391 1.5 19V21M23.5 21V19C23.4993 18.1137 23.2044 17.2528 22.6614 16.5523C22.1184 15.8519 21.3581 15.3516 20.5 15.13M16.5 3.13C17.3604 3.3503 18.123 3.8507 18.6676 4.55231C19.2122 5.25392 19.5078 6.11683 19.5078 7.005C19.5078 7.89317 19.2122 8.75608 18.6676 9.45769C18.123 10.1593 17.3604 10.6597 16.5 10.88M13.5 7C13.5 9.20914 11.7091 11 9.5 11C7.29086 11 5.5 9.20914 5.5 7C5.5 4.79086 7.29086 3 9.5 3C11.7091 3 13.5 4.79086 13.5 7Z"
                        stroke="#404A61"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_14488_54179">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="translate(0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                {{ analyticsData?.totalAudience || 0 }}
              </h3>
              <p class="font-14 base-color m-0">
                Audience(s) have entered the flow through the
                {{ modalTitle[nodeName] }}
              </p>
            </div>
          </div>
        </div>
        <div
          v-else-if="
            ['addToListAction', 'removeFromListAction'].includes(nodeName)
          "
        >
          <div class="border-top-normal-1 border-bottom-normal-1 py-28 px-2">
            <div class="text-center">
              <h3 class="font-24 font-w-500 base-color mb-2">
                <span v-if="nodeName == 'addToListAction'" class="me-2">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_14488_54230)">
                      <path
                        d="M16.5 21V19C16.5 17.9391 16.0786 16.9217 15.3284 16.1716C14.5783 15.4214 13.5609 15 12.5 15H5.5C4.43913 15 3.42172 15.4214 2.67157 16.1716C1.92143 16.9217 1.5 17.9391 1.5 19V21M20.5 8V14M23.5 11H17.5M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7Z"
                        stroke="#101828"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_14488_54230">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="translate(0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <span v-else class="me-2">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 21V19C16 17.9391 15.5786 16.9217 14.8284 16.1716C14.0783 15.4214 13.0609 15 12 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21M23 11H17M12.5 7C12.5 9.20914 10.7091 11 8.5 11C6.29086 11 4.5 9.20914 4.5 7C4.5 4.79086 6.29086 3 8.5 3C10.7091 3 12.5 4.79086 12.5 7Z"
                      stroke="#101828"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                {{ analyticsData?.totalAudience || 0 }}
              </h3>
              <p class="font-14 base-color m-0">
                Audiences
                {{ nodeName == "removeFromListAction" ? "removed" : "added" }}
                from the list :
                {{ nodeData?.selectedDropdownvalue?.text }}
              </p>
            </div>
          </div>
        </div>
        <div v-else-if="nodeName == 'sendEmailAction'">
          <div class="border-top-normal-1 border-bottom-normal-1">
            <div class="text-center">
              <div
                class="bg-gray-5 py-20 px-2 d-flex align-items-center justify-content-center gap-4"
              >
                <h3
                  class="m-0 font-16 font-w-500 d-inline-flex align-items-center"
                >
                  <span class="dot my-0 ms-0 me-2 h-8 w-8 purple"></span> Sent:
                  {{ analyticsData?.uniqueSent || 0 }}
                </h3>
                <h3
                  class="m-0 font-16 font-w-500 d-inline-flex align-items-center"
                >
                  <span class="dot my-0 ms-0 me-2 h-8 w-8 green"></span>
                  Delivered: {{ analyticsData?.uniqueDelivered || 0 }}
                </h3>
                <h3
                  class="m-0 font-16 font-w-500 d-inline-flex align-items-center"
                >
                  <span class="dot my-0 ms-0 me-2 h-8 w-8 red"></span> Bounce:
                  {{ analyticsData?.uniqueBounced || 0 }}
                </h3>
              </div>
              <div class="row mx-0">
                <div
                  class="col-6 py-18 border-right-normal-1 border-color-gray-50"
                >
                  <h3 class="font-16 font-w-500 base-color mb-1">
                    {{
                      percentageCalc(
                        analyticsData.uniqueOpened,
                        analyticsData.uniqueDelivered
                      ) || 0
                    }}%
                  </h3>
                  <p class="font-14 base-color m-0">Open Rate</p>
                </div>
                <div class="col-6 py-18">
                  <h3 class="font-16 font-w-500 base-color mb-1">
                    {{
                      percentageCalc(
                        analyticsData.uniqueClicked,
                        analyticsData.uniqueDelivered
                      ) || 0
                    }}%
                  </h3>
                  <p class="font-14 base-color m-0">Click Rate</p>
                </div>
              </div>
              <div class="row mx-0 border-top-normal-1 border-color-gray-50">
                <div
                  class="col-6 py-18 border-right-normal-1 border-color-gray-50"
                >
                  <h3 class="font-16 font-w-500 base-color mb-1">
                    {{
                      percentageCalc(
                        analyticsData.totalConversion,
                        analyticsData.uniqueDelivered
                      ) || 0
                    }}%
                  </h3>
                  <p class="font-14 base-color m-0">Conversion</p>
                </div>
                <div class="col-6 py-18">
                  <h3 class="font-16 font-w-500 base-color mb-1">
                    ${{ analyticsData?.totalConversionValue || 0 }}
                  </h3>
                  <p class="font-14 base-color m-0">Conv. value</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="nodeName == 'tagAssignedAction'">
          <div class="border-top-normal-1 border-bottom-normal-1 py-28">
            <div class="text-center">
              <h3 class="font-24 font-w-500 base-color mb-2">
                <span class="me-2">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_14488_54179)">
                      <path
                        d="M17.5 21V19C17.5 17.9391 17.0786 16.9217 16.3284 16.1716C15.5783 15.4214 14.5609 15 13.5 15H5.5C4.43913 15 3.42172 15.4214 2.67157 16.1716C1.92143 16.9217 1.5 17.9391 1.5 19V21M23.5 21V19C23.4993 18.1137 23.2044 17.2528 22.6614 16.5523C22.1184 15.8519 21.3581 15.3516 20.5 15.13M16.5 3.13C17.3604 3.3503 18.123 3.8507 18.6676 4.55231C19.2122 5.25392 19.5078 6.11683 19.5078 7.005C19.5078 7.89317 19.2122 8.75608 18.6676 9.45769C18.123 10.1593 17.3604 10.6597 16.5 10.88M13.5 7C13.5 9.20914 11.7091 11 9.5 11C7.29086 11 5.5 9.20914 5.5 7C5.5 4.79086 7.29086 3 9.5 3C11.7091 3 13.5 4.79086 13.5 7Z"
                        stroke="#404A61"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_14488_54179">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="translate(0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                {{ analyticsData?.totalAudience || 0 }}
              </h3>
              <p class="font-14 base-color m-0">
                Audiences tagged with :
                {{
                  nodeData?.selectedDropdownvalue
                    ?.map((item) => item.text)
                    .join(", ")
                }}
              </p>
            </div>
          </div>
        </div>
        <div
          v-else-if="
            ['tagRemovedAction', 'tagAssignedAction'].includes(nodeName)
          "
        >
          <div class="border-top-normal-1 border-bottom-normal-1 py-28 px-2">
            <div class="text-center">
              <h3 class="font-24 font-w-500 base-color mb-2">
                <span v-if="nodeName == 'tagAssignedAction'" class="me-2">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_14488_54230)">
                      <path
                        d="M16.5 21V19C16.5 17.9391 16.0786 16.9217 15.3284 16.1716C14.5783 15.4214 13.5609 15 12.5 15H5.5C4.43913 15 3.42172 15.4214 2.67157 16.1716C1.92143 16.9217 1.5 17.9391 1.5 19V21M20.5 8V14M23.5 11H17.5M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7Z"
                        stroke="#101828"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_14488_54230">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="translate(0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <span v-else class="me-2">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 21V19C16 17.9391 15.5786 16.9217 14.8284 16.1716C14.0783 15.4214 13.0609 15 12 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21M23 11H17M12.5 7C12.5 9.20914 10.7091 11 8.5 11C6.29086 11 4.5 9.20914 4.5 7C4.5 4.79086 6.29086 3 8.5 3C10.7091 3 12.5 4.79086 12.5 7Z"
                      stroke="#101828"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                {{ analyticsData?.totalAudience || 0 }}
              </h3>
              <p class="font-14 base-color m-0">
                Audiences
                {{ nodeName == "tagRemovedAction" ? "untagged" : "added" }}
                from :
                {{
                  nodeData?.selectedDropdownvalue
                    ?.map((item) => item.text)
                    .join(", ")
                }}
              </p>
            </div>
          </div>
        </div>
        <div v-else-if="nodeName == 'waitTimeAction'">
          <div class="border-top-normal-1 border-bottom-normal-1 py-28 px-2">
            <div class="text-center">
              <h3 class="font-24 font-w-500 base-color mb-2">
                <span class="me-2">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.8467 20.4227H11.8851V22.4998H10.8467V20.4227Z"
                      fill="#24243A"
                    />
                    <path
                      d="M14.0664 19.9894L15.0259 19.5922L15.8206 21.5112L14.8611 21.9084L14.0664 19.9894Z"
                      fill="#24243A"
                    />
                    <path
                      d="M16.875 18.3564L17.6094 17.6221L19.0779 19.0906L18.3435 19.825L16.875 18.3564Z"
                      fill="#24243A"
                    />
                    <path
                      d="M18.8438 15.7748L19.2414 14.8154L21.16 15.6105L20.7624 16.5698L18.8438 15.7748Z"
                      fill="#24243A"
                    />
                    <path
                      d="M19.6748 11.5964H21.7518V12.6349H19.6748V11.5964Z"
                      fill="#24243A"
                    />
                    <path
                      d="M1.57422 15.6105L3.49286 14.8154L3.89047 15.7748L1.97182 16.5698L1.57422 15.6105Z"
                      fill="#24243A"
                    />
                    <path
                      d="M18.8438 8.45586L20.7627 7.66101L21.1602 8.62053L19.2412 9.41538L18.8438 8.45586Z"
                      fill="#24243A"
                    />
                    <path
                      d="M3.65527 19.0922L5.12382 17.6237L5.85818 18.358L4.38963 19.8266L3.65527 19.0922Z"
                      fill="#24243A"
                    />
                    <path
                      d="M16.874 5.87242L18.3429 4.40405L19.0771 5.13841L17.6082 6.60678L16.874 5.87242Z"
                      fill="#24243A"
                    />
                    <path
                      d="M6.91016 21.5123L7.70518 19.5936L8.66452 19.9912L7.8695 21.9099L6.91016 21.5123Z"
                      fill="#24243A"
                    />
                    <path
                      d="M14.0664 4.24053L14.8611 2.32153L15.8206 2.71879L15.0259 4.63779L14.0664 4.24053Z"
                      fill="#24243A"
                    />
                    <path
                      d="M2.53879 12.1155C2.53879 7.24998 6.5006 3.28843 11.3658 3.28843V2.25C5.92397 2.25 1.5 6.67388 1.5 12.1158L2.53879 12.1155Z"
                      fill="#24243A"
                    />
                    <path
                      d="M11.3672 11.5957H7.73242V12.6342H12.4056V4.32654H11.3672V11.5957Z"
                      fill="#24243A"
                    />
                  </svg>
                </span>
                {{ analyticsData?.totalAudience || 0 }}
              </h3>
              <p class="font-14 base-color m-0">Audiences are waiting</p>
            </div>
          </div>
        </div>
        <div v-else-if="nodeName == 'conditionalSplitProcess'">
          <div
            class="border-top-normal-1 border-bottom-normal-1 pt-18 pb-0 px-2"
          >
            <div class="text-center">
              <h3 class="font-24 font-w-500 base-color mb-0">
                {{ analyticsData?.totalAudience || 0 }}
              </h3>
              <p class="font-14 base-color m-0">Entered the condition</p>
              <div class="row mt-18 mx-0">
                <div class="col-6 py-18">
                  <h3 class="font-16 font-w-500 base-color mb-0">
                    {{ analyticsData?.conditionTrueCount || 0 }}
                  </h3>
                  <p class="font-14 base-color m-0">Yes</p>
                </div>
                <div class="col-6 py-18">
                  <h3 class="font-16 font-w-500 base-color mb-0">
                    {{ analyticsData?.conditionFalseCount || 0 }}
                  </h3>
                  <p class="font-14 base-color m-0">No</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="['profileUpdateAction'].includes(nodeName)">
          <div class="border-top-normal-1 border-bottom-normal-1 py-28 px-2">
            <div class="text-center">
              <h3 class="font-24 font-w-500 base-color mb-2">
                <span class="me-2">
                  <svg
                    width="25"
                    height="20"
                    viewBox="0 0 25 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 19V17C17.5 15.9391 17.0786 14.9217 16.3284 14.1716C15.5783 13.4214 14.5609 13 13.5 13H5.5C4.43913 13 3.42172 13.4214 2.67157 14.1716C1.92143 14.9217 1.5 15.9391 1.5 17V19M23.5 19V17C23.4993 16.1137 23.2044 15.2528 22.6614 14.5523C22.1184 13.8519 21.3581 13.3516 20.5 13.13M16.5 1.13C17.3604 1.3503 18.123 1.8507 18.6676 2.55231C19.2122 3.25392 19.5078 4.11683 19.5078 5.005C19.5078 5.89317 19.2122 6.75608 18.6676 7.45769C18.123 8.1593 17.3604 8.6597 16.5 8.88M13.5 5C13.5 7.20914 11.7091 9 9.5 9C7.29086 9 5.5 7.20914 5.5 5C5.5 2.79086 7.29086 1 9.5 1C11.7091 1 13.5 2.79086 13.5 5Z"
                      stroke="#101828"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                {{ analyticsData?.totalAudience || 0 }}
              </h3>
              <template
                v-for="(item, index) in this.profileUpdateSteps"
                :key="index"
              >
                <p v-html="generateProfileText(item)"></p>
              </template>
            </div>
          </div>
        </div>
        <div v-else-if="['actionAlertAction'].includes(nodeName)">
          <div class="border-top-normal-1 border-bottom-normal-1 py-28 px-2">
            <div class="text-center">
              <h3 class="font-24 font-w-500 base-color mb-2">
                <span class="me-2">
                  <svg
                    width="17"
                    height="20"
                    viewBox="0 0 17 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 13H9.5V11H11.5V9H9.5V7H7.5V9H5.5V11H7.5V13ZM0.5 17V15H2.5V8C2.5 6.61667 2.91667 5.3875 3.75 4.3125C4.58333 3.2375 5.66667 2.53333 7 2.2V1.5C7 1.08333 7.14583 0.729167 7.4375 0.4375C7.72917 0.145833 8.08333 0 8.5 0C8.91667 0 9.27083 0.145833 9.5625 0.4375C9.85417 0.729167 10 1.08333 10 1.5V2.2C11.3333 2.53333 12.4167 3.2375 13.25 4.3125C14.0833 5.3875 14.5 6.61667 14.5 8V15H16.5V17H0.5ZM8.5 20C7.95 20 7.47917 19.8042 7.0875 19.4125C6.69583 19.0208 6.5 18.55 6.5 18H10.5C10.5 18.55 10.3042 19.0208 9.9125 19.4125C9.52083 19.8042 9.05 20 8.5 20ZM4.5 15H12.5V8C12.5 6.9 12.1083 5.95833 11.325 5.175C10.5417 4.39167 9.6 4 8.5 4C7.4 4 6.45833 4.39167 5.675 5.175C4.89167 5.95833 4.5 6.9 4.5 8V15Z"
                      fill="#1C1B1F"
                    />
                  </svg>
                </span>
                {{ analyticsData?.totalAudience || 0 }}
              </h3>
              <p>
                alert sent to
                <strong>{{ generateEmails() }}</strong> with subject
                <strong>{{ actionAlertConfiguration?.emailSubject }}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="hasError" class="text-center">
        <div
          class="d-flex justify-content-center align-items-center flex-column h-162"
        >
          <div class="mb-12">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                stroke="#101828"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <p class="font-14 m-0">{{ errorMessages }}</p>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="d-flex justify-content-end">
        <kbutton @click="modalHandler" class="">Close</kbutton>
      </div>
    </template>
  </modal>
</template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import modal from "@/components/common/AppModal/AppModal.vue";
import {
  getFlowAutomationTriggerAnalyticsService,
  getFlowAutomationActionAnalyticsService,
} from "@/services/clubDetails/flowAutomation.service";
import moment from "moment";

export default {
  components: {
    kbutton: Button,
    modal,
  },
  computed: {
    modalVisibility() {
      return this.$store.state?.flowAutomation?.flowAnalyticsModalVisibility;
    },
    selectedFlowElement() {
      return this.$store.state?.flowAutomation?.selectedFlowElement;
    },
  },
  data: function () {
    return {
      hasError: false,
      errorMessages: "",

      clubId: this.$route.params.id,
      isLoading: false,
      analyticsData: null,
      nodeName: "",
      nodeData: {},
      modalTitle: {
        metricsTrigger: "Metric Trigger",
        tagAssignedTrigger: "Tag Assigned Trigger",
        tagRemovedTrigger: "Tag Removed Trigger",
        emailActionTrigger: "Email Action Trigger",
        enterSegmentTrigger: "Enter Segment Trigger",
        listEntryTrigger: "List Entry Trigger",
        priceDropTrigger: "Price Drop Trigger",
        lowStockAlertTrigger: "Low Stock Alert Trigger",
        dateAttributeTrigger: "Date Attribute Trigger",

        addToListAction: "Add to List",
        removeFromListAction: "Remove from List",
        sendEmailAction: "Send Email",
        tagAssignedAction: "Tag Assigned",
        tagRemovedAction: "Tag Removed",
        exitFromWorkflowAction: "Exit from Workflow",
        waitTimeAction: "Wait Time",
        profileUpdateAction: "Profile Update",
        actionAlertAction: "Action Alert",

        conditionalSplitProcess: "Conditional Split",
      },
      modalClasses: {
        metricsTrigger: "",
        tagAssignedTrigger: "",
        tagRemovedTrigger: "",
        emailActionTrigger: "",
        enterSegmentTrigger: "",
        listEntryTrigger: "",

        addToListAction: "",
        removeFromListAction: "",
        sendEmailAction: "",
        tagAssignedAction: "",
        tagRemovedAction: "",
        exitFromWorkflowAction: "",
        waitTimeAction: "",

        conditionalSplitProcess: "",
      },

      profileUpdateSteps: [],
      actionAlertConfiguration: {},
    };
  },
  mounted() {
    let { id, name, data } = this.selectedFlowElement;
    this.nodeName = name;
    this.nodeData = data?.data;

    if (this.nodeName == "profileUpdateAction") {
      this.profileUpdateSteps = this.nodeData?.profileUpdateSteps;
    }

    if (this.nodeName == "actionAlertAction") {
      this.actionAlertConfiguration = this.nodeData?.actionAlertConfiguration;
    }

    let payload = {
      id: id,
      clubId: this.clubId,
    };

    if (name.includes("Trigger")) {
      this.getFlowAutomationTriggerAnalytics(payload);
    } else {
      this.getFlowAutomationActionAnalytics(payload);
    }
  },
  methods: {
    generateEmails() {
      return this.actionAlertConfiguration?.emailSendTo.split(",").join(", ");
    },
    isValidDate(value) {
      if (value && typeof value === "object" && value instanceof Date)
        return true;

      if (typeof value !== "string" && typeof value !== "number") return false;

      const date = new Date(value);
      return date instanceof Date && !isNaN(date);
    },

    generateProfileText(item) {
      let value = item?.value?.label
        ? item?.value?.label
        : this.isValidDate(item?.value)
        ? moment(item?.value).format("DD/MM/YYYY")
        : item?.value != null
        ? item?.value
        : "N/A";
      if (item?.actionType?.id == 3) {
        return `<p>Properties Removed: <strong>${item?.fieldType?.alterText}</strong> </p>`;
      }
      if (item?.actionType?.id == 1) {
        return `<p>Properties updated with <strong>${item?.fieldType?.alterText}</strong> : <strong>${value}</strong> </p>`;
      }
    },
    async getFlowAutomationTriggerAnalytics(payload) {
      this.isLoading = true;
      try {
        let response = await getFlowAutomationTriggerAnalyticsService(payload);
        this.analyticsData = response?.data?.data;
      } catch (error) {
        this.hasError = true;
        this.errorMessages = error.response?.data?.Status?.Message;
      } finally {
        this.isLoading = false;
      }
    },
    async getFlowAutomationActionAnalytics(payload) {
      this.isLoading = true;
      try {
        let response = await getFlowAutomationActionAnalyticsService(payload);
        this.analyticsData = response?.data?.data;
      } catch (error) {
        this.hasError = true;
        this.errorMessages = error.response?.data?.Status?.Message;
      } finally {
        this.isLoading = false;
      }
    },
    modalHandler(data) {
      this.$store.commit("SET_FLOW_ANALYTICS_MODAL_VISIBILITY", false);
      this.$store.commit("SET_SELECTED_FLOW_ELEMENT", {});
    },
    generateTextFromArr(arr) {
      return arr.map((item) => item.text).join(", ");
    },
    percentageCalc(value, total) {
      if (!value || !total) {
        return 0;
      } else {
        if (isFinite(value / total)) {
          return parseFloat(((value / total) * 100).toFixed(2));
        } else {
          return 0;
        }
      }
    },
  },
};
</script>
