<template>
  <el-container class="min-h-100vh" id="teleportContainer">
    <el-container class="">
      <el-main>
        <div id="drawflow">
          <div class="zoom-in-out-wrap">
            <!-- <p class="measure">100%</p> -->
            <kbutton
              @click="zoomIn"
              :icon="'plus'"
              :class="'h-38 w-38'"
            ></kbutton>
            <kbutton
              @click="zoomOut"
              :icon="'minus'"
              :class="'h-38 w-38'"
            ></kbutton>
          </div>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import Drawflow from "drawflow";
import styleDrawflow from "drawflow/dist/drawflow.min.css";
import style from "../style.scss";

import {
  onMounted,
  shallowRef,
  h,
  getCurrentInstance,
  render,
  readonly,
  ref,
  defineComponent,
} from "vue";
import enterSegmentTrigger from "../components/triggers/enterSegmentTrigger.vue";
import metricsTrigger from "../components/triggers/metricsTrigger.vue";
import tagAssignedTrigger from "../components/triggers/tagAssignedTrigger.vue";
import tagRemovedTrigger from "../components/triggers/tagRemovedTrigger.vue";
import listEntryTrigger from "../components/triggers/listEntryTrigger.vue";
import emailActionTrigger from "../components/triggers/emailActionTrigger.vue";
import priceDropTrigger from "../components/triggers/priceDropTrigger.vue";
import dateAttributeTrigger from "../components/triggers/dateAttributeTrigger.vue";
import lowStockAlertTrigger from "../components/triggers/lowStockAlertTrigger.vue";

import sendEmailAction from "../components/actions/sendEmailAction.vue";
import tagAssignedAction from "../components/actions/tagAssignedAction.vue";
import tagRemovedAction from "../components/actions/tagRemovedAction.vue";
import mergeBlockAction from "../components/actions/mergeBlockAction.vue";
import removeFromListAction from "../components/actions/removeFromListAction.vue";
import addToListAction from "../components/actions/addToListAction";
import profileUpdateAction from "../components/actions/profileUpdateAction.vue";
import actionAlertAction from "../components/actions/actionAlertAction.vue";
import waitTimeAction from "../components/actions/waitTimeAction.vue";
import exitFromWorkflowAction from "../components/actions/exitFromWorkflowAction.vue";
import conditionalSplitProcess from "../components/process/conditionalSplitProcess.vue";
import { Button } from "@progress/kendo-vue-buttons";

import BackPageIcon from "../../svg-icons/backPageIcon.vue";
import SendEmailIcon from "../../svg-icons/sendEmailIcon.vue";
import formSubmissionIcon from "../../svg-icons/formSubmissionIcon.vue";
import listEntryIcon from "../../svg-icons/listEntryIcon.vue";
import enterSegmentIcon from "../../svg-icons/enterSegmentIcon.vue";
import emailActionIcon from "../../svg-icons/emailActionIcon.vue";
import tagAssignedIcon from "../../svg-icons/tagAssignedIcon.vue";
import tagRemovedIcon from "../../svg-icons/tagRemovedIcon.vue";
import metricsIcon from "../../svg-icons/metricsIcon.vue";
import priceDropIcon from "../../svg-icons/priceDropIcon.vue";
import addToListIcon from "../../svg-icons/addToListIcon.vue";
import removeFromListIcon from "../../svg-icons/removeFromListIcon.vue";
import updateFieldIcon from "../../svg-icons/updateFieldIcon.vue";
import internalNotificationEmailIcon from "../../svg-icons/internalNotificationEmailIcon.vue";
import exitFromWorkflowIcon from "../../svg-icons/exitFromWorkflowIcon.vue";
import waitTimeIcon from "../../svg-icons/waitTimeIcon.vue";
import conditionalSplitIcon from "../../svg-icons/conditionalSplitIcon.vue";

export default defineComponent({
  components: {
    BackPageIcon,
    SendEmailIcon,
    formSubmissionIcon,
    listEntryIcon,
    enterSegmentIcon,
    emailActionIcon,
    tagAssignedIcon,
    tagRemovedIcon,
    metricsIcon,
    priceDropIcon,
    addToListIcon,
    removeFromListIcon,
    updateFieldIcon,
    internalNotificationEmailIcon,
    exitFromWorkflowIcon,
    waitTimeIcon,
    conditionalSplitIcon,
    kbutton: Button,
  },
  name: "emailWorkFlowEditor",
  data() {
    return {};
  },
  props: {
    workflowData: {
      type: Object,
      default: () => {},
    },
  },
  methods: {},
  setup(props) {
    const triggerNodes = readonly([
      {
        name: "List Entry",
        item: "listEntryTrigger",
        input: 0,
        output: 1,
        iconName: "listEntryIcon",
      },
      {
        name: "Enter a Segment",
        item: "enterSegmentTrigger",
        input: 0,
        output: 1,
        iconName: "enterSegmentIcon",
      },
      {
        name: "Email Action",
        item: "emailActionTrigger",
        input: 0,
        output: 1,
        iconName: "emailActionIcon",
      },
      {
        name: "Tag Assigned",
        item: "tagAssignedTrigger",
        input: 0,
        output: 1,
        iconName: "tagAssignedIcon",
      },
      {
        name: "Tag Removed",
        item: "tagRemovedTrigger",
        input: 0,
        output: 1,
        iconName: "tagRemovedIcon",
      },
      {
        name: "Metrics",
        item: "metricsTrigger",
        input: 0,
        output: 1,
        iconName: "metricsIcon",
      },
      {
        name: "Price Drop",
        item: "priceDropTrigger",
        input: 0,
        output: 1,
        iconName: "priceDropIcon",
      },
      {
        name: "Date Attribute",
        item: "dateAttributeTrigger",
        input: 0,
        output: 1,
        iconName: "priceDropIcon",
      },
      {
        name: "Low Stock Alert",
        item: "lowStockAlertTrigger",
        input: 0,
        output: 1,
        iconName: "priceDropIcon",
      },
    ]);

    const actionNodes = readonly([
      {
        name: "Send Email",
        item: "sendEmailAction",
        input: 1,
        output: 1,
        iconName: "SendEmailIcon",
      },
      {
        name: "Add To List",
        item: "addToListAction",
        input: 1,
        output: 1,
        iconName: "addToListIcon",
      },
      {
        name: "Remove From List",
        item: "removeFromListAction",
        input: 1,
        output: 1,
        iconName: "removeFromListIcon",
      },
      {
        name: "Tag Assigned",
        item: "tagAssignedAction",
        input: 1,
        output: 1,
        iconName: "tagAssignedIcon",
      },
      {
        name: "Tag Removed",
        item: "tagRemovedAction",
        input: 1,
        output: 1,
        iconName: "tagRemovedIcon",
      },
      {
        name: "Wait Time",
        item: "waitTimeAction",
        input: 1,
        output: 1,
        iconName: "waitTimeIcon",
      },
      {
        name: "Merge Block",
        item: "mergeBlockAction",
        input: 2,
        output: 1,
        iconName: "mergeBlockIcon",
      },
      {
        name: "Profile Update",
        item: "profileUpdateAction",
        input: 1,
        output: 1,
        iconName: "profileUpdateIcon",
      },
      {
        name: "Action Alert",
        item: "actionAlertAction",
        input: 1,
        output: 1,
        iconName: "actionAlertIcon",
      },
      {
        name: "Exit",
        item: "exitFromWorkflowAction",
        input: 1,
        output: 0,
        iconName: "exitFromWorkflowIcon",
      },
    ]);

    const processNodes = readonly([
      {
        name: "Conditional Split",
        item: "conditionalSplitProcess",
        input: 1,
        output: 2,
        iconName: "conditionalSplitIcon",
      },
    ]);

    const listNodes = readonly([
      ...triggerNodes,
      ...actionNodes,
      ...processNodes,
    ]);

    const triggerNames = [
      "enterSegmentTrigger",
      "metricsTrigger",
      "tagAssignedTrigger",
      "tagRemovedTrigger",
      "listEntryTrigger",
      "emailActionTrigger",
      "priceDropTrigger",
      "dateAttributeTrigger",
      "lowStockAlertTrigger",
    ];

    const editor = shallowRef({});
    const dialogVisible = ref(false);
    const dialogData = ref({});
    const Vue = { version: 3, h, render };
    const internalInstance = getCurrentInstance();
    internalInstance.appContext.app._context.config.globalProperties.$df =
      editor;

    function zoomIn() {
      editor.value.zoom_in();
    }

    function zoomOut() {
      editor.value.zoom_out();
    }

    onMounted(() => {
      const id = document.getElementById("drawflow");
      editor.value = new Drawflow(
        id,
        Vue,
        internalInstance.appContext.app._context
      );
      editor.value.start();
      editor.value.zoom_refresh();

      editor.value.registerNode("metricsTrigger", metricsTrigger, {}, {});
      editor.value.registerNode(
        "tagAssignedTrigger",
        tagAssignedTrigger,
        {},
        {}
      );
      editor.value.registerNode("tagRemovedTrigger", tagRemovedTrigger, {}, {});
      editor.value.registerNode("listEntryTrigger", listEntryTrigger, {}, {});
      editor.value.registerNode(
        "emailActionTrigger",
        emailActionTrigger,
        {},
        {}
      );
      editor.value.registerNode("priceDropTrigger", priceDropTrigger, {}, {});
      editor.value.registerNode(
        "dateAttributeTrigger",
        dateAttributeTrigger,
        {},
        {}
      );
      editor.value.registerNode(
        "lowStockAlertTrigger",
        lowStockAlertTrigger,
        {},
        {}
      );

      editor.value.registerNode("sendEmailAction", sendEmailAction, {}, {});
      editor.value.registerNode("addToListAction", addToListAction, {}, {});
      editor.value.registerNode(
        "profileUpdateAction",
        profileUpdateAction,
        {},
        {}
      );
      editor.value.registerNode("actionAlertAction", actionAlertAction, {}, {});
      editor.value.registerNode(
        "removeFromListAction",
        removeFromListAction,
        {},
        {}
      );
      editor.value.registerNode("mergeBlockAction", mergeBlockAction, {}, {});
      editor.value.registerNode("waitTimeAction", waitTimeAction, {}, {});
      editor.value.registerNode(
        "exitFromWorkflowAction",
        exitFromWorkflowAction,
        {},
        {}
      );
      editor.value.registerNode("tagAssignedAction", tagAssignedAction, {}, {});
      editor.value.registerNode("tagRemovedAction", tagRemovedAction, {}, {});
      editor.value.registerNode(
        "conditionalSplitProcess",
        conditionalSplitProcess,
        {},
        {}
      );
      editor.value.registerNode(
        "enterSegmentTrigger",
        enterSegmentTrigger,
        {},
        {}
      );
      editor.value.useuuid = true;
      editor.value.editor_mode = "fixed";
      // editor.value.zoom = 0.4;
      editor.value.zoom_refresh();

      // only one connection between nodes at a time logic

      if (props?.workflowData) {
        editor.value.import(props?.workflowData);
      } else {
        editor.value.import({
          drawflow: {
            Home: {
              data: {},
            },
          },
        });
      }
    });
    return {
      zoomIn,
      zoomOut,
      listNodes,
      triggerNodes,
      actionNodes,
      processNodes,
    };
  },

  mounted() {
    this.flowName = this.$route.query.flow_name;
    this.flowNameForEdit = this.$route.query.flow_name;
  },
});
</script>
<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #494949;
}

.container {
  min-height: calc(100vh - 100px);
}

.column {
  border-right: 1px solid #494949;
  max-height: calc(100vh - 60px);
  overflow-y: auto;
}

.node {
  border-radius: 4px;
  border: 1px solid #e9ebef;
  background: #f8f9fa;
  height: 40px;
  line-height: 22px;
  padding: 8px;
  margin: 12px 0px;
  cursor: move;
  position: relative;
}

.node::before {
  content: "";
  background: rgba(43, 53, 76, 0.12);
  border-radius: 2px;
  height: 24px;
  width: 3px;
  margin-right: 10px;
}

#drawflow {
  width: 100%;
  height: 100%;
  text-align: initial;
  background: #2b2c30;
  background-size: 20px 20px;
  background-image: radial-gradient(#494949 1px, transparent 1px);
}
</style>
