<template src="./viewFlowDetails.html" lang=""></template>
<style lang="scss">
@import "./viewFlowDetails.scss";
</style>

<script>
import { Input } from "@progress/kendo-vue-inputs";
import { Button } from "@progress/kendo-vue-buttons";
import { RadioButton } from "@progress/kendo-vue-inputs";
import Breadcrumb from "@/components/common/AppBreadCrumbs/BreadCrumbs.vue";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar.vue";
import { RadioGroup } from "@progress/kendo-vue-inputs";
import flowViewComponent from "@/components/workflowAutomation/common/flowViewComponent.vue";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import WarningModal from "../../emailCampaign/warningModal/WarningModal.vue";
import {
  updateFlowAutomationStatusService,
  getFlowDetailsService,
  cloneFlowAutomationService,
} from "@/services/clubDetails/flowAutomation.service";
import flowAnalyticsModal from "@/components/workflowAutomation/common/flowAnalyticsModal.vue";
export default {
  name: "flowDetails",
  components: {
    "k-input": Input,
    kbutton: Button,
    Breadcrumb,
    radiobutton: RadioButton,
    AppSnackBar: AppSnackBar,
    radiogroup: RadioGroup,
    WarningModal,
    AppLoader,
    flowViewComponent,
    flowAnalyticsModal,
  },
  data() {
    return {
      routeClubId: this.$route.params.id,
      autoFlowStatusList: {
        1: "Draft",
        2: "Live",
        3: "Scheduled",
        4: "Paused",
        5: "Completed",
        6: "Cancelled",
      },

      flowStatusColor: {
        1: "draft",
        2: "live",
        3: "scheduled",
        4: "paused",
        5: "live",
        6: "",
      },

      flowId: this.$route.params.flowId,
      flowDetails: {},
      isLoading: false,
      statusChangeLoading: false,
      notifyMessage: "",
      notifyType: "",
      isNotify: false,
      clubName: this.$route.query.club_name,
      editorData: null,

      resumeFlowWarningModalVisibility: false,
      resumeFlowWarningModalDetails: {
        title: "Resume Automation",
        message:
          "You are about to resume automation that was paused. Are you sure you want to resume the automation?",
        confirmButton: "Resume Flow",
        cancelButton: "Cancel",
      },

      pauseFlowWarningModalVisibility: false,
      pauseFlowWarningModalDetails: {
        title: "Pause Automation",
        message:
          "All automation activities will be paused, and no recipients will enter the flow automation if paused. And no further analytics will be generated. Are you sure you want to pause the automation?",
        confirmButton: "Pause Flow",
        cancelButton: "Cancel",
      },
    };
  },

  computed: {
    flowAnalyticsModalVisibility() {
      return this.$store.state?.flowAutomation?.flowAnalyticsModalVisibility;
    },
    formattedCampaignName() {
      return this.generateName(this.flowDetails?.autoflowName);
    },
  },

  mounted() {
    if (!this.hasFlowCampaignGetPermission()) {
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "You do not have access to flow automation.",
        type: "warning",
      });
      this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
    }

    const payload = {
      id: this.$route.params.flowId,
    };
    this.getFlowDetails(payload);
  },

  methods: {
    hasFlowCampaignPostPermission() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: "emailCampaign",
        itemCode: "flowAutomation",
        childItemCode: null,
      });
    },
    hasFlowCampaignPutPermission() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: "emailCampaign",
        itemCode: "flowAutomation",
        childItemCode: null,
      });
    },
    hasFlowCampaignGetPermission() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: "emailCampaign",
        itemCode: "flowAutomation",
        childItemCode: null,
      });
    },
    hasFlowCampaignDeletePermission() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: "emailCampaign",
        itemCode: "flowAutomation",
        childItemCode: null,
      });
    },
    generateName(name) {
      if (!name) {
        return "";
      }
      return name.length > 70 ? name.substring(0, 70) + "..." : name;
    },
    cloneAndEditHandler() {
      const payload = {
        id: this.$route.params.flowId,
        autoFlowStatus: 1,
        clubId: this.routeClubId,
      };

      this.isLoading = true;
      cloneFlowAutomationService(payload)
        .then((res) => {
          this.isLoading = false;
          let flowId = res.data.data.id;

          this.$router.push({
            name: "workflowEditorUpdate",
            params: {
              ...this.$route.params,
              flowId: flowId,
            },
            query: {
              type: 2,
              // flow_name: this?.flowDetails?.autoflowName,
              // flow_type: this?.flowDetails?.flowTemplateId || 1,
            },
          });
        })
        .catch((err) => {
          this.isLoading = false;
          this.showNotification("Something went wrong.", "error");
        });
    },

    navigateToFlowEdit() {
      this.$router.push({
        name: "workflowEditorUpdate",
        params: {
          ...this.$route.params,
          flowId: this?.flowDetails?.id,
        },
        query: {
          type: 2,
          flow_name: this?.flowDetails?.autoflowName,
          flow_type: this?.flowDetails?.flowTemplateId || 1,
        },
      });
    },

    getFlowDetails(payload, isFromUpdate = false) {
      isFromUpdate ? (this.isLoading = false) : (this.isLoading = true);
      payload.clubId = this.routeClubId;
      getFlowDetailsService(payload)
        .then((res) => {
          this.flowDetails = res.data.data;
          this.editorData = JSON.parse(this.flowDetails?.editorData);
          this.$store.commit(
            "SET_IS_EDIT_MODE_NODE_DROPDOWN",
            this.flowDetails?.autoFlowStatus == 2 ? true : false
          );
          // this.$store.commit("SET_CAMPAIGNIDS_EMAIL_LIST",this.flowDetails?.campaignIds)
          this.isLoading = false;

          if (this.flowDetails?.autoFlowStatus == 1) {
            this.$router.replace({
              name: this.$route.name,
              params: this.$route.params,
              query: {
                ...this.$route.query,
                isDraft: true,
              },
            });
          }
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },

    resumeCampaignHandler() {
      this.resumeFlowWarningModalVisibility = true;
    },

    onCancelResumeFlow() {
      this.resumeFlowWarningModalVisibility = false;
    },

    onConfirmResumeFlow() {
      this.resumeFlowWarningModalVisibility = false;
      const payload = {
        id: this.flowDetails?.id,
        currentStatus: 2,
        previousStatus: this.flowDetails?.autoFlowStatus,
      };
      this.flowAutomationStatusChangeHandler(payload);
    },

    puaseCampaignHandler() {
      if (this.flowDetails?.autoFlowStatus == 3) {
        this.pauseFlowWarningModalVisibility = true;
        this.pauseCampaignWarningModalDetails.message =
          "You are about to pause your campaign that is scheduled. You may have to reschedule if you want to resume this campaign. Are you sure you want to pause this scheduled campaign?";
      } else {
        this.pauseFlowWarningModalVisibility = true;
      }
    },

    onCancelPauseFlow() {
      this.pauseFlowWarningModalVisibility = false;
    },

    onConfirmPauseFlow() {
      this.pauseFlowWarningModalVisibility = false;
      const payload = {
        id: this.flowDetails?.id,
        currentStatus: 4,
        previousStatus: this.flowDetails?.autoFlowStatus,
      };
      this.flowAutomationStatusChangeHandler(payload);
    },

    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        2000
      );
    },
    doNavigateToFlowAutomationHomepage() {
      this.$router.push({
        name: "flowAutomation",
      });
    },

    navigateToViewAnalytics() {
      this.$store.commit(
        "SET_EMAIL_CAMPAIGN_NAME",
        this?.flowDetails?.campaignName
      );
      this.$router.push({
        name: "CampaignAnalytics",
        params: {
          ...this.$route.params,
          id: this?.flowDetails?.id,
        },
        query: {
          type: 2,
          campaignName: this?.flowDetails?.campaignName,
        },
      });
    },
    navigateToEmailList() {
      this.$store.commit(
        "SET_EMAIL_CAMPAIGN_NAME",
        this?.flowDetails?.autoflowName
      );
      this.$router.push({
        name: "flowEmailList",
        params: {
          ...this.$route.params,
        },
        query: {
          ...this.$route.query,
          type: 2,
          flowAutomationName: this?.flowDetails?.autoflowName,
          campaignIds: this.flowDetails?.campaignIds,
        },
      });
    },

    statusChangeSuccessMessageGenerator(status) {
      if (status == 4) {
        return "Campaign has been paused.";
      } else if ([2, 3].includes(status)) {
        return "Campaign has been resumed.";
      } else if (status == 5) {
        return "Campaign has been ended.";
      } else if (status == 6) {
        return "Campaign has been cancelled.";
      }
    },

    flowAutomationStatusChangeHandler(payload) {
      this.statusChangeLoading = true;

      let message = this.statusChangeSuccessMessageGenerator(
        payload.autoFlowStatus
      );

      payload = {
        ...payload,
        clubId: this.routeClubId,
      };

      updateFlowAutomationStatusService(payload)
        .then((res) => {
          this.statusChangeLoading = false;
          this.showNotification(message, "success");
          const payload = {
            id: this.$route.params.flowId,
          };
          this.getFlowDetails(payload, true);
        })
        .catch((err) => {
          this.statusChangeLoading = false;
          this.showNotification("Something went wrong.", "error");
        });
    },
  },
};
</script>
<style lang=""></style>
