<AppSnackBar :message="notifyMessage" :notificationType="notifyType" v-if="isNotify"></AppSnackBar>
<app-loader v-if="isLoading || statusChangeLoading"></app-loader>
<div class="dashboard-right-inner">
  <Breadcrumb :id="$route.params.id" />
  <div v-if="!isLoading" class="dashboard-header box-shadow-none p-0">
    <div class="d-flex flex-wrap justify-content-between align-items-center py-9 px-20">
      <div>
        <div class="d-flex flex-wrap align-items-center mb-1">
          <h4 class="font-16 font-w-500 mb-0 me-3">
            {{formattedCampaignName || "Dummy Flow Name"}}
          </h4>
          <span v-if="!flowDetails?.isDefault"
            :class="['campaign-status styled-2 font-12 font-w-600 radius-100 py-0 px-8 h-22 ',flowStatusColor[flowDetails?.autoFlowStatus]]">{{autoFlowStatusList[flowDetails?.autoFlowStatus]}}</span>
        </div>
        <!-- <div>
                    <p class="font-12 text-color-2 m-0">Scheduled to be sent on: Sept 7th 2022 at 5:30am Time_Zone</p>
                </div> -->
      </div>

      <div class="mt-2 mt-xl-0 w-100-vsm d-flex gap-3">
        <div class="d-flex gap-3">
          <div v-if="[4].includes(flowDetails?.autoFlowStatus)" class="d-flex justify-content-start align-items-center">
            <kbutton :class="'h-38 w-100-vsm border-normal-1 text-color'" :size="'medium'"
              :disabled="this.hasFlowCampaignPutPermission() == false" :theme-color="'primary'"
              @click="resumeCampaignHandler" :fill-mode="'outline'">Resume Flow
            </kbutton>
          </div>

          <div v-if="[2].includes(flowDetails?.autoFlowStatus)" class="d-flex justify-content-start align-items-center">
            <kbutton :class="'h-38 w-100-vsm border-normal-1 text-color'" :size="'medium'"
              :disabled="this.hasFlowCampaignPutPermission() == false" :theme-color="'primary'"
              @click="puaseCampaignHandler" :fill-mode="'outline'">Pause Flow
            </kbutton>
          </div>

          <div v-if="[1,4].includes(flowDetails?.autoFlowStatus) && routeClubId == flowDetails?.clubId"
            class="d-flex justify-content-start align-items-center">
            <!-- <div v-if="[4].includes(flowDetails?.autoFlowStatus)"
                            class="dot purple h-10 w-10 radius-100 ms-0 ms-sm-2 me-2 d-none d-sm-inline-block">
                        </div> -->
            <kbutton :class="'h-38 w-100-vsm border-normal-1 text-color'" :size="'medium'"
              :disabled="this.hasFlowCampaignPutPermission() == false" :theme-color="'primary'"
              @click="navigateToFlowEdit" :fill-mode="'outline'">Edit Flow
            </kbutton>
          </div>
          <div
            v-if="[1].includes(flowDetails?.autoFlowStatus) && routeClubId != flowDetails?.clubId && flowDetails?.isDefault"
            class="d-flex justify-content-start align-items-center">
            <kbutton :class="'h-38 w-100-vsm border-normal-1 text-color'" :size="'medium'"
              :disabled="this.hasFlowCampaignPostPermission() == false" :theme-color="'primary'"
              @click="cloneAndEditHandler" :fill-mode="'flat'">Use This Flow
            </kbutton>
          </div>
        </div>
        <!-- <kbutton :fill-mode="'outline'" :class="'h-38 w-100-vsm border-normal-1 text-color'" :theme-color="'primary'"
          @click="doNavigateToFlowAutomationHomepage">
          Return to Flows
        </kbutton> -->
        <!-- <kbutton v-if="!flowDetails?.isDefault" :fill-mode="'outline'"
          :class="'h-38 w-100-vsm border-normal-1 text-color'" :disabled="true" :theme-color="'primary'"
          @click="navigateToViewAnalytics">
          View Analytics
        </kbutton> -->
        <kbutton v-if="flowDetails?.campaignIds" :fill-mode="'outline'"
          :class="'h-38 w-100-vsm border-normal-1 text-color'" :disabled="false" :theme-color="'primary'"
          @click="navigateToEmailList">
          Email List
        </kbutton>
      </div>
    </div>
    <!-- <div class="pt-19 px-20 pb-12 bg-gray-2">
            <h6 class="font-16 font-w-400 text-color-2 mb-0">Flow Trigger</h6>
            <p class="font-14 font-w-500 text-color mb-0">Only contacts who are not tagged as tag_name will enter this flow automation.</p>
            <div style="border-top: 1px solid #DEE0E6; margin-top: 12px;"></div>
        </div> -->
  </div>

  <!--dashboard-content-->
  <div v-if="!isLoading"
    class="dashboard-content my-campaign-dashboard-content max-height-100-247 p-0 h-100 min-h-100vh--174">
    <!-- <div class="p-24">
            <div class="flow-details-body-content border-bottom-normal-1">
                <p class="font-16 l-height-24 text-color-2 mb-8">Flow Trigger</p>
                <p class="font-16 l-height-24 base-color mb-8">Only contacts who <b>are not tagged</b> as <b>tag_name</b> will enter this flow automation.</p>
            </div>
        </div> -->
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between"
      :class="[1,4].includes(flowDetails?.autoFlowStatus) && routeClubId == flowDetails?.clubId ? 'flow-details-details-body' : ''">
      <flowViewComponent v-if="editorData" :workflowData="editorData" />
    </div>
  </div>
</div>

<WarningModal v-if="pauseFlowWarningModalVisibility" :modalDetails="pauseFlowWarningModalDetails"
  :onCancel="onCancelPauseFlow" :onConfirm="onConfirmPauseFlow" />

<WarningModal v-if="resumeFlowWarningModalVisibility" :modalDetails="resumeFlowWarningModalDetails"
  :onCancel="onCancelResumeFlow" :onConfirm="onConfirmResumeFlow" />

<flowAnalyticsModal v-if="flowAnalyticsModalVisibility" :flowDetails="flowDetails" />